<template>
  <v-main>
    <v-container>
      <v-divider></v-divider>
      <div id="blog-post">
        <div class="text-left">
          <h1>{{ post.data.title }}</h1>
          <h4>
            Autor:
            {{ post.data.author.first_name }}{{ post.data.author.last_name }}
          </h4>
        </div>
        <v-divider class="mb-3 mt-2"></v-divider>
        <div v-html="post.data.body"></div>
        <v-divider class="my-6"></v-divider>
        <div class="card-container-blog">
          <v-btn
            class="card-btn-before"
            color="#f88930"
            v-if="post.meta.previous_post"
            :to="/Blog/ + post.meta.previous_post.slug"
          >
            <v-icon class="mr-2">mdi-arrow-left-bold</v-icon>
            {{ post.meta.previous_post.title }}
          </v-btn>
          <!-- <div class="text-center">More Blogs</div> -->
          <v-btn
            class="card-btn-after"
            color="#395B9A"
            v-if="post.meta.next_post"
            :to="/Blog/ + post.meta.next_post.slug"
          >
            {{ post.meta.next_post.title }}
            <v-icon class="ml-2">mdi-arrow-right-bold</v-icon>
          </v-btn>
        </div>
      </div>
    </v-container>
  </v-main>
</template>
<script>
export default {
  name: "Blog-post",
  data() {
    return {
      post: {
        meta: {
          previous_post: { slug: "3d", title: "escaneo 3d" },
          next_post: { slug: "asbuilt", title: "planos asbuilt" },
        },
        data: {
          title: "Ejemplo de blog",
          author: { first_name: "Esto", last_name: "si" },
          body:
            "<div><img src='https://www.w3schools.com/w3images/p3.jpg' alt='Bear' style='width:40%; height:50%; display: block; margin-left: auto; margin-right: auto;'><div style='position: relative; text-align: center;'><br>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Suscipit odio impedit magni optio est aliquam sint, harum porro mollitia veritatis quod qui quasi deserunt! Libero, culpa? Molestiae et ut dolorum.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Suscipit odio impedit magni optio est aliquam sint, harum porro mollitia veritatis quod qui quasi deserunt! Libero, culpa? Molestiae et ut dolorum.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Suscipit odio impedit magni optio est aliquam sint, harum porro mollitia veritatis quod qui quasi deserunt! Libero, culpa? Molestiae et ut dolorum.Lorem, ipsum dolor sit amet consectetur adipisicing elit. Suscipit odio impedit magni optio est aliquam sint, harum porro mollitia veritatis quod qui quasi deserunt! Libero, culpa? Molestiae et ut dolorum.</div></div>",
        },
      },
    };
  },
};
</script>
